@import 'src/app/styles/vars';

.Greeting {
  width: 100%;
  margin-bottom: $spacing-medium-x;
}

.Greeting__title {
  margin-bottom: $spacing-small-x;
  font-weight: $font-weight-regular;
  letter-spacing: 0.03em;
}

.Greeting__text {
  color: $color-text-secondary;
  width: 70%;
  margin-bottom: $spacing-medium-x;

  @include mobile-down {
    width: 100%;
  }
}