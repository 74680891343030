$spacing-small-4x: 4px;
$spacing-small-3x: 8px;
$spacing-small-2x: 12px;
$spacing-small-x: 16px;
$spacing-small: 24px;
$spacing-medium: 32px;
$spacing-medium-x: 40px;
$spacing-large: 48px;
$spacing-large-x: 64px;
$spacing-large-2x: 72px;
$spacing-large-3x: 96px;
$spacing-large-4x: 124px;
